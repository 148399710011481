/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import "sass-lib";

@media(min-width: 1px) and (max-width: 690px){
	h3{font-size: 20px; line-height: 26px;}
	h6{font-size: 14px; line-height: 20px;}
	p{font-size: 14px; line-height: 22px;}
	.wrapper-lg, .wrapper{padding: 0 20px;}
	.box-title{
		padding-left: 15px; margin: 44px 0 30px;
		h2{font-size: 20px;line-height: 24px;}
		h6{line-height: 18px; margin-bottom: 5px;}
		.line-vly{left: 0;}
		&.nopadd{
			padding-left: 20px;
			.line-vly{left: 0;}
		}
	}
	.btn-oval{
		&.btn-submit-big{font-size: 16px; height: 40px;}
		&.btn-thankspage{font-size: 14px;}
	}

	.row_list{
        width: auto;display: block;
        .col{
            &.col_xsml{width: 100%;}
            &.col_xsml7{width: 70%;}
            &.col_xsml65{width: 65%;}
            &.col_xsml6{width: 60%;}
            &.col_xsml55{width: 55%;}
            &.col_xsml5{width: 50%;}
            &.col_xsml45{width: 45%;}
            &.col_xsml4{width: 40%;}
            &.col_xsml37{width: 37%;}
            &.col_xsml35{width: 35%;}
            &.col_xsml33{width: 33.3333333333333%;}
            &.col_xsml3{width: 30%;}
            &.col_xsml25{width: 25%;}
            &.col_xsml2{width: 20%;}
            &.col_xsml16{width: 16.66666666666666%;}
            &.col_xsml1{width: 10%;}
        }
        &.center{
            text-align: center;
            .col{float: none; display: inline-block;}
        }
        &.listfull{
            margin-left: 0; margin-right: 0;
            .col{padding: 0;}
        }
        &.listp5{
            margin-left: 0; margin-right: 0;
            .col{padding: 0;}
        }
    }

	header{
		.nav_header{
			.nav_mobile{
				width: 100%;
				li{padding: 30px 30px 30px 70px;}
				.bg-close{width: 100%;}
				.box-sosmed-mb{padding-top: 30px;}
			}
		}
	}

	.slidehome{
		.list-slide{
			min-height: 480px;
			.desc{
				h1{font-size: 20px; line-height: 22px; margin-bottom: 25px;}
				h5{font-size: 12px;}
			}
		}
	}
	.slidehome .list-slide .desc .line-yl, .slidehome .list-slide .desc footer .foot-top .box-link-foot h5:before, footer .foot-top .box-link-foot .slidehome .list-slide .desc h5:before{height: 5px; margin-bottom: 15px;}

	.box-banner-registration{
		.btn-pink{font-size: 12px;}
		.left{
			width: 50%;
			h4{font-size: 12px;}
		}
		.right{width: 50%;}
	}
	.box-proteksi{
		padding: 40px 20px;
		.img-proteksi{display: none;}
		.desc-proteksi{
			width: 100%; height: auto;
			h3{
				font-size: 20px; line-height: 24px;
				&.hline{
					.line-yl{left: 0; top: -40px;}
				}
			}
		}
		.left{
			padding-top: 20px;padding-bottom: 30px; margin-bottom: 32px; border-bottom: 1px solid #ececec;
			.desc-proteksi{padding-right: 0;}
		}
		.right{
			padding-top: 20px;
			.desc-proteksi{
				text-align: left; padding-left: 0;
				h3{
					&.hline{
						.line-yl{right: auto; left: 0; top: -40px;}
					}
				}
			}
		}
	}
	.sec-about{
		.left{
			width: 100%;
			figure{
				height: 200px;
				img{height: auto; min-height: 100%; width: 100%; @include transform(translate(-50%, 0)); top: 0;}
			}
		}
		.right{
			width: 100%; position: relative; @include transform(translateY(0)); top: 0;padding: 45px 20px 35px;
			h1{font-size: 22px; line-height: 26px;}
			p{display: none;}
			.hline{
				.line-yl{left: 0; top: -40px;}
			}
		}
	}
	.box-banner-solution{
		padding: 56px 0 35px;
		.hline{
			.line-yl{left: 50%; top: -20px; margin-left: -15px;}
		}
		.left{width: 100%; text-align: center; padding-left: 0; margin-bottom: 30px;}
		.right{
			width: 100%; position: relative; top: 0; @include transform(translateY(0)); text-align: center; right: 0;
			.btn-oval{
				margin-bottom: 15px; margin-left: 5px; margin-right: 5px;
				&:first-child{margin-left:5px;}
			}
		}
	}
	.box-news-home{
		padding: 32px 0;
		.box-title-news{
			width: 100%; margin-bottom: 30px;
			h1{margin-bottom: 15px;}
		}
		.box-news-list-home{
			width: 100%;
			.list{
				width: 100%; margin-right: 0; margin-bottom: 25px;
				&:last-child{margin-bottom: 0;}
			}
		}
	}
	.banner{
		height: 120px;
		&.banner-slide{
			height: 300px;
		}
	}
	.slidenews{
		.desc{width: auto; left: 30px; right: 30px;}
		.list{
			height: 300px;
			figure{
				img{max-width: none; height: 100%; min-width: 100%; position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%));}
			}
		}
	}
	.box-product{
		margin-top: 30px;
		.list-product{
			margin-bottom: 45px;
			h3{margin-bottom: 10px;}
			p{margin-bottom: 20px;}
			.img-prod{
				margin-bottom: 20px;
				figure{
					height: auto;
					img{position: relative; top: auto; left: auto; @include transform(translate(0, 0)); max-width: 100%; height: auto;}
				}
			}
			.desc-prod{
				padding-left: 0; padding-top: 20px;
				.hline{
					.line-yl{left: 0; top: -40px;}
				}
			}
		}
	}
	.box-btn-tab{
		.title-btn-tab{font-size: 14px; height: 40px; line-height: 40px;}
		.list{padding: 0 20px; line-height: 40px; height: 40px; font-size: 12px;}
	}
	.box-board{
		.list-board{
			margin-bottom: 45px;
			h3.hline{
				.line-yl{left: 0; top: -40px;}
			}
			.img-board{width: 100%; margin-bottom: 30px; text-align: center;}
			.desc-board{width: 100%; padding-top: 20px;}
			&:nth-child(2n){
				text-align: left;
				h3.hline{
					.line-yl{left: 0; top: -40px; right: auto;}
				}
			}
		}
	}

	.box-content{
		h4{font-size: 18px; line-height: 24px; margin-bottom: 15px;}
		figure{margin-bottom: 20px;}
		ol, ul{
			color: #666666;
		 	li{font-size: 14px; line-height: 22px;}
		}
		p{color: #666666;}
		iframe{height: 250px;}
	}
	.ln-fix{margin-bottom: 15px;}
	.box-two-list{
		h6{margin-bottom: 0;}
		.list{
			margin-bottom: 55px;
			.hline{
				.line-yl{top: -40px; left: 0;}
			}
			.left{display: none; width: 100%;}
			.right{width: 100%; position: relative; top: auto; left: auto; right: auto; text-align: left; @include transform(translate(0, 0));}
			&:nth-child(2n){
				.right{
					text-align: left;
					.hline{
						.line-yl{left: 0; right: auto;}
					}
				}
			}
		}
	}
	.box-three-list{
		.list{width: 100%; margin-bottom: 40px;}
	}
	.box-four-list{
		.list{
			width: 100%; margin-bottom: 40px;
			&:nth-child(4n + 1){clear: inherit;}
		}
	}

	.box-visi{
		.list{min-height: 0; margin-bottom: 20px;}
	}

	aside{
		margin: 0 -20px;
		.title-aside{font-size: 13px;}
		ul{
			padding: 0 20px;
			li{
				a{font-size: 14px;}
			}
		}
	}

	article{
		padding: 30px 0;
		.box-title{
			padding-left: 15px;
			.line-vly{left: 0;}
		}
	}

	.box-drag-content{
		.list{
			.img-rekam{
				width: 100%;
				figure{margin-bottom: 0;}
			}
			.desc-rekam{
				width: 100%; padding-top: 30px;
				h3{margin-bottom: 10px;}				
			}
		}
	}
	.box-drag-nav-slide{
		&.ui-slider-horizontal{
			left: 40px; right: 0;
			.ui-slider-handle{width: 80px;margin-left: -25px;}
		}
	}

	.box-link-reward{
		.list{
			padding: 22px 15px 22px 45px;
			&:after{display: none;}
			h5{font-size: 14px; margin-bottom: 5px; display: block; float: none;}
			span{font-size: 12px; line-height: 14px; margin-bottom: 0;}
			.hline{
				.line-yl{left: -30px;}
			}
			a{position: relative; top: auto; left: auto; right: auto; width: auto; display: inline-block; font-size: 12px; @include transform(translateY(0));}
			&:hover, &.active{
				.line-yl{width: 22px;}
			}
		}
	}
	.box-filter{
		margin-bottom: 30px;
		&.box-filter-rekanan{
			select{width: 100%; margin-bottom: 20px;}
			.btn-submit{width: 100%;}
		}
	}

	.career-link-send{
		margin-left: 0; margin-top: 20px;
		&.hline{
			.line-yl{left: 0; top: -35px;}
		}
	}

	.box-form{
		input[type="text"], input[type="email"], select{height: 40px; font-size: 16px;}
		@include placeholder{font-size: 12px;}
		textarea{padding-top: 15px; padding-bottom: 15px; font-size: 16px; height: 115px; @include borderRadius(20px);}
		.row_list{
			.col{margin-bottom: 20px;}
		}
		.btn-oval.btn-submit-big{width: 100%;}
		.yciputra{font-size: 14px;}
		.question{font-size: 14px;}
		.sready{font-size: 14px; line-height: 18px;}
	}
	.input_file{
		height: 40px;
		span.browse{font-size: 12px; max-height: 12px;}
	}
	.g-recaptcha{@include transform(scale(0.8)); @include transformOrigin(0);}
	.opt{font-size: 14px;}
	.box-form-career{margin-bottom: 60px;}
	.thankspage{
		min-height: 450px; padding-top: 65px;
		.desc{
			h1{font-size: 25px;}
			h4{font-size: 14px; line-height: 22px;}
		}
	}

	.box-accordion{
		h5{font-size: 14px;}
		.list-accordion{
			padding: 22px 30px 22px 42px;
			.hline{
				.line-yl{left: -28px;}
			}
			&.active, &:hover{
				.line-yl{width: 20px;}
			}
			.sub-accordion{padding-top: 15px;}
		}
	}

	.box-list-address{
		p{min-height: 0;}
		.inner-address{padding-bottom: 25px; border-bottom: 1px solid #acacac;}
		.col{margin-bottom: 35px;}
	}

	.slidetitle{
		.box-slidetitle{
			width: 100%; height: 40px;
			.list-slidetitle{width: 50%; height: 40px; line-height: 40px; font-size: 12px;}
		}
	}
	.box-qst-cpt{
		.left{margin-bottom: 10px;}
	}
	.box-search-filter-result{
		width: 100%; margin-bottom: 30px;
		span{padding-left: 30px;}
	}
	.box-search-filter{
		input[type=submit]{right: 20px;}
	}
	.box-sitemap{
		font-size: 14px;
		h5{font-size: 16px;}
	}
	.box-news-list{
		.list{
			width: 100%;
			h5{font-size: 16px; line-height: 24px;}
			figure{
				height: 260px; position: relative;
				img{position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%)); width: 100%; max-height: none; min-height: 100%;}
			}
			.desc{
				padding: 20px 20px 50px;
				.corner-link-bottom{left: 20px;}
			}
			&:hover{
				figure{
					img{
						@include transform(translate(-50%, -50%) scale(1.1));
					}
				}
			}
		}
	}
	.box-news-vids{
		figure{height: 230px;}
		p{max-height: none;}
		.left, .right{width: 100%;}
		.right{padding: 25px 40px 50px}
	}
	.box-detail-news{
		.box-title{
			padding-left: 20px;
			.line-vly{left: 0;}
		}
	}

	.box-arrow-page{
		.btn-oval.btn-ln-grey{
			min-width: 170px; margin-bottom: 10px; max-width: 100%;
			&.left{margin-right: 10px;}
			&.right{text-align: right; float: left;}
		}
	}
	.box-list-news-right{
		.list-news{
			width: 100%; padding-right: 0;
			&:nth-child(2n){padding-left: 0;}
		}
	}

	.box-manfaat{
		figure{margin-bottom: 0;}
		.list-manfaat{
			width: 100%;
			.left{width: 18%;}
			.right{width: 75%;}
			&:nth-child(2n + 1){clear: inherit;}
		}
	}
	.blog-box{
		.lg,.thumb{width: 100%;}
		.thumb{
			.list{
				margin-right: 0;width: 49.8%;
				.desc{padding: 0 15px 10px;}
				&:nth-child(even){float: right;}
			}
		}
	}
	.sliderblog-lg{
		.list{
			.desc{
				padding: 0 15px 15px;
				span{font-size: 14px;margin-bottom: 0;}
				h2{font-size: 20px;line-height: 26px;max-height: 52px;}
			}
		}
	}
	.link-list{
		a{font-size: 14px;line-height: 18px;}
	}

	footer{
		.foot-top{
			padding-bottom: 50px;
			.prof-foot{width: 100%; text-align: center;}
			.box-link-foot{width: 100%; display: none;}
		}
	}
	
	.slidetitle{
		.box-slidetitle{
			width: 50%;
			.list-slidetitle{width: 50%;}
		}
	}
	.box-form {
		.row_list{
			.col.no-marg{
				margin-bottom: 20px;
			}
		}	
	}
	.ls-Testi .ic {width: 120px; height: 120px;}

	.section-telemarketing {
		.list-box {
			.box-item {
				display: block; margin: 0 0 40px;
				&:last-child {margin: 0;}
				&:nth-child(2n) {
					figcaption {padding-left: 40px; padding-right: 0;}
					&:before {left: 0; top: 10px;}
				}
			}
			figure {
				width: 100%; margin: 0 0 25px;
				img {width: 100%;}
			}
			figcaption {
				width: 100%;
				&:before {left: 0; top: 10px;}
			}
			&.first-right {
				.box-item {
					figure {}
					figcaption {
						padding-left: 40px; padding-right: 0;
						&:before {left: 0; top: 10px;}
					}
					&:nth-child(2n) {
						figcaption {padding-left: 40px; padding-right: 0;}
						&:before {left: 0; top: 10px;}
					}

				}
			}
		}
		&.gradient {background-position: 40% center; background-size: cover;}
	}
	
}

@media(min-width: 400px){
	.box-search{
		input[type=text]{display: block !important;}
		.btn-search{display: block !important;}
		.btn-search-mb{display: none !important;}
	}

}
@media(min-width: 1px) and (max-width: 420px){
	header{
		.nav_header{
			.nav_right{
				.box-search{display: none;}
			}
		}
	}
}
@media(min-width: 1px) and (max-width: 400px){
	header{
		height: 51px;
		.logo{
			img{width: 95px;}
		}
		.nav_header{
			margin-top: 16px;
			.nav_right{padding-top: 0;}
		}
		.burg-menu{
			/*height: 20px;*/
			/*span{
				&:nth-child(2){top: 8px;}
				&:nth-child(3){top: 16px;}
			}*/
		}
		&.float{
			height: 51px;
		}
	}
	.cart-link{
		&:after{display: none;}
	}

	.btn-oval{padding-left: 15px;padding-right: 15px;}

	.box-search{
		position: static; padding-right: 10px;
		input[type=text]{position: absolute; top:100%; left: 0; right: 0; width: 100%; background: rgba(0, 120, 88, 0.5); color: $white; @include boxSizing(border-box); height: 30px; padding-right: 40px; display: none;}
		@include placeholder{color: $white;}
		.btn-search{position: absolute; top: 100%; right: 20px; margin-top: 7px; display: none;}
		.btn-search-mb{display: block;}
	}
	.box-search-nav{
		background: #023770; padding: 15px 30px; position: relative; display: block;
		&:before{content: ""; position: absolute; top: 25px; right: 30px; width: 17px; height: 17px; background: url('../images/material/ic_search.png') no-repeat center;}
		input{
			height: 40px; padding: 0 25px 0 0; @include boxSizing(border-box); width: 100%; background: transparent; color: #fff; border: none; border-bottom: 1px solid #355f8d;
			@include andplaceholder{color: #fff;}
		}
	}
	.box-lang{padding-left: 10px;}

	.slidehome{
		.list-slide{padding-top: 51px;}
	}
	.box-banner-registration{
		.left{padding-top: 8px;}
		.btn-pink{letter-spacing: -1px;}
	}
	.box-content{
		iframe{height: 180px;}
	}
	.box-news-list{
		.list{
			figure{height: 200px;}
		}
	}
	.sec-news-list{padding: 30px 0;}

	.box-content {
		.wrap_btn2{
			height: auto;
		}
		p {
		    margin: 5px 0 15px;
		    font-size: 16px; line-height: 24px;
		}
	}

	.banner,.margtophead{margin-top: 51px;}
}

@media (max-width: 767px){
	.wrap_popup {
		.pop_inner {
			display: block;
			.content_pop {width: 100%; padding: 0 15px; @include boxSizing(border-box);}
			.box_pop{display: block;}
			.content_pop{
				margin: 0;
				article{width: 100%; margin-top: 60px; padding-top: 0; margin-bottom: 0; padding-bottom: 0;}
			}
			.close{background-size: 100%; width: 30px; height: 30px; top: 20px; right: 20px;}
		}
	}
	.slidetitle{
		.box-slidetitle{
			width: 60%;
			.list-slidetitle{width: 50%; padding: 0 20px;}
		}
	}
	.middle-nobanner {
		padding-top: 100px;
	}
	.section-telemarketing {
		padding: 40px 0;
		.wrapper {padding: 0 20px;}
		.title-section {font-size: 18px; line-height: 26px; margin-bottom: 30px;}
		.list-icon {
			.item {
				margin-bottom: 25px;
				.ico {width: 44px;}
				.keterangan {line-height: 22px;}
			}
			&.solusi {
				margin: 0;
				.item {padding: 0; width: 100%;}
			}
		}
		.form-cek {
			display: block; margin-top: 35px;
			.cek-number {
				width: 100%;
				form {
					position: relative; border-radius: 20px;width: 100%;
					input[type="text"],
					input[type="number"] {
						  height: 40px; font-size: 14px; padding: 0 100px 0 60px;
					}
					span {font-size: 14px; line-height: 18px; padding-right: 10px; left: 20px;}
					button {
						font-size: 12px; border-radius: 20px; padding: 0 25px;
					}
				}
			}
			> p {width: 100%;}
		}
	}

	.wrap-notif .pop_inner {
		display: table;
		.box_pop {display: table-cell; width: 100%;}
		.content_pop {
			width: auto; padding: 30px 20px 50px; margin: 10% 20px;
			.notif-telemarketing {
				.ico {width: 80px; margin: 0 auto 25px;}
				p {font-size: 16px}
				.close-notif {line-height: 40px; min-width: 140px;}
			}
		}

	}
}

